import {DocumentReference} from "firebase/firestore";
import {IRatings} from "@/models/interfaces/ratings";

interface IAnime {
  id: string
  name: {
    ja: string
    en: string
  }
  tagIds: string[]
  cours: string[]
  ratings:IRatings;
}

interface IAnimeClass {
  props: IAnime
}

class Anime implements IAnimeClass {
  props: IAnime

  constructor(props: IAnime) {
    this.props = props
  }

}

export default Anime