import logo from '../../logo.svg'
import React from 'react'
import styles from './tag.module.css'
import { Box, Chip, Stack, Typography } from '@mui/material'
import AnimeList from '../AnimeList'
import SearchModal from '@/Component/SearchModal'
import LoginModal from '@/Component/LoginModal'
import useUser from 'hooks/useUser'
import AnimeSummarySection from "@/Component/AnimePage/AnimeSummarySection";
import {useParams} from "react-router-dom";
import useAnime from "@/hooks/useAnime";
import CommentItem from "@/Component/AnimePage/AnimeComment/CommentList/CommentItem";
import CommentList from "Component/AnimePage/AnimeComment/CommentList";
import CommentInput from "@/Component/AnimePage/CommentInput";
import AnimeComment from "@/Component/AnimePage/AnimeComment";


const AnimePage: React.FC = props => {
  const { user, setUser } = useUser()
  const { id } = useParams();
  const anime = useAnime({id: id ?? ""});

  return (
    id ? <Stack id='stack'>
      <AnimeSummarySection id={id} />
      <AnimeComment id={id}/>
      <CommentInput id={id}/>
    </Stack> : null
  )
}

export default AnimePage
