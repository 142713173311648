import { atom } from 'recoil'
import User from '@/models/entities/user'

export const userStore = atom<User>({
  key: 'userStore',
  default: new User({
    uid: undefined,
    displayName: null,
    token: '',
    type: 'guest',
    photoURL: '',
  }),
})
