import {DocumentReference} from "firebase/firestore";
import { atom } from 'recoil'
import Anime from "@/models/entities/anime";

export const animeStore = atom<Anime>({
  key: 'animeStore',
  default: new Anime({
    id: '',
    name: {'ja':'', 'en': ''},
    tagIds: [],
    cours: [],
    ratings: {
      story: 0,
      character: 0,
      animation: 0,
      message: 0,
      worldview: 0,
    }
  }),
})
