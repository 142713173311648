import React from 'react'
import { useRecoilState } from 'recoil'
import { searchModalState } from '@/store/serchModalState'
import { Box, Modal } from '@mui/material'
import TagsSection from '@/Component/TagsSection'
import theme from '@/theme/theme'
import {tagState} from "@/store/tagState";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  bgcolor: theme.palette.background.default,
  p: 2,
  maxWidth: '800px',
}

const SearchModal: React.FC = props => {
  const [open, setOpen] = useRecoilState<boolean>(searchModalState)
  const [tagsState, setTagsState] = useRecoilState<string[]>(tagState)

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={style}>
        <TagsSection tagsState={tagsState} setTagsState={setTagsState} />
      </Box>
    </Modal>
  )
}

export default SearchModal
