import { useEffect, useState } from 'react'
import { userStore } from '@/store/userStore'
import { getAuth } from 'firebase/auth'
import { useRecoilState } from 'recoil'
import User from '@/models/entities/user'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '@/firebase'

const useUser = () => {
  const [user, setUser] = useRecoilState(userStore)

  useEffect(() => {
    const auth = getAuth()
    const unsubscribe = auth.onAuthStateChanged(firebaseUser => {
      if (user.props.token === '') {
        if (firebaseUser) {
          firebaseUser.getIdToken().then(token => {
            const userDocRef = doc(db, 'versions/1/users', firebaseUser.uid)
            getDoc(userDocRef).then(docSnapshot => {
              const userData = docSnapshot.data()
              setUser(
                new User({
                  uid: userDocRef.id,
                  token: token,
                  photoURL: userData?.photoURL,
                  displayName: userData?.displayName,
                  type: userData?.type,
                })
              )
            })
          })
        } else {
          setUser(
            new User({
              uid: undefined,
              token: 'guest',
              displayName: null,
              photoURL: null,
              type: 'guest',
            })
          )
        }
      }
    })

    // クリーンアップ関数でリスナーを解除
    return () => unsubscribe()
  }, [])

  const refreshUserData = async () => {
    if(!user.props.uid) return
    const userDocRef = doc(db, 'versions/1/users', user.props.uid)
    const docSnapshot =await getDoc(userDocRef)
    const userData = docSnapshot.data()
    setUser(
      new User({
        uid: user.props.uid,
        token:  user.props.token,
        photoURL: userData?.photoURL,
        displayName: userData?.displayName,
        type: userData?.type,
      })
    )
  }

  return { user, setUser, refreshUserData }
}

export default useUser
