import useUser from '@/hooks/useUser'

import React, {ReactNode, useEffect} from 'react'

import { getAuth, signOut } from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {useRecoilState} from "recoil";
import {customSnackbarState} from "@/store/customSnackbarState";
import {guestUser} from "@/models/entities/user";

interface Props {
}

const LogoutPage: React.FC = props => {
  const { user, setUser } = useUser()
  const navigate = useNavigate();
  const [message, setMessage] = useRecoilState<string>(customSnackbarState);
  useEffect(()=>{
    (async()=>{
      const auth = getAuth(); // Firebase Auth インスタンスを取得
      try {
        await signOut(auth); // ログアウト処理
        setUser(guestUser);
        setMessage("ログアウトしました");
        navigate('/');
      } catch (error) {
        console.assert(error);
        setMessage("ログアウトに失敗しました:");
        navigate('/');
      }
    })()
  }, [])

  return  <></>
}

export default LogoutPage
