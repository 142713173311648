import React from 'react'
import './App.css'
import {Stack, Box, AppBar, Toolbar, Button, useScrollTrigger, Container} from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme/theme'
import CssBaseline from '@mui/material/CssBaseline'
import BottomNavigationBar from './Component/BottomNavigationBar'
import ListPage from './Component/ListPage'
import MatrixPage from './Component/MatrixPage'
import Chip from '@mui/material/Chip'
import UpperAppBar from '@/Component/UpperAppBar'
import { RecoilRoot } from 'recoil'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AdminPage from '@/Component/AdminPage'
import AnimePage from "@/Component/AnimePage";
import AccountPage from "@/Component/AccountPage";
import LogoutPage from "@/Component/LogoutPage";
import LoadingModal from "@/features/LoadingModal";
import CustomSnackbar from "@/features/CostomSnackbar";
import LoginPage from "@/Component/LoginPage";

function App() {
  const [navValue, setNavValue] = React.useState(1)

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <LoadingModal />
        <CustomSnackbar />
        <CssBaseline />
        <BrowserRouter>
          <Box
            sx={{
              padding: '0, 20px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Container maxWidth="sm">
              <Routes>
                <Route path="/" element={<ListPage />} />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/animes/:id" element={<AnimePage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/logout" element={<LogoutPage />} />
                <Route path="/login" element={<LoginPage />} />
              </Routes>
            </Container>
          </Box>
          <UpperAppBar />
        </BrowserRouter>

        {/*<BottomNavigationBar handleChange={setNavValue}/>*/}
      </ThemeProvider>
    </RecoilRoot>
  )
}

export default App
