
import { Box, Chip, Stack, Typography } from '@mui/material'
import useUser from 'hooks/useUser'
import AdminGuard from '@/Component/AdminGurd'
import Menu, { MenuProps } from '@mui/material/Menu';
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AddTitle from "@/Component/AdminPage/AddTitle";

const AdminPage: React.FC = props => {
  const { user, setUser } = useUser()

  return (
    <AdminGuard>
      <AddTitle />
    </AdminGuard>
  )
}

export default AdminPage
