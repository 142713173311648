import useUser from '@/hooks/useUser'

import React, {ReactNode, useEffect} from 'react'

import {getAuth, GoogleAuthProvider, signInWithPopup, signOut} from "firebase/auth";
import {useRecoilState} from "recoil";
import {loginModalState} from "@/store/loginModalState";
import {userStore} from "@/store/userStore";
import {doc, getDoc, serverTimestamp, setDoc} from "firebase/firestore";
import {db} from "@/firebase";
import User from "@/models/entities/user";
import {Box} from "@mui/material";
import GoogleButton from "react-google-button";
import theme from "@/theme/theme";
import {Navigate, useNavigate} from 'react-router-dom'

const style = {
  marginTop: '50px',
}

interface Props {
}

const LoginPage: React.FC = props => {
  const [user, setUser] = useRecoilState(userStore)
  const provider = new GoogleAuthProvider()
  const navigate = useNavigate();

  const handleLogin = async () => {
    const auth = getAuth()
    signInWithPopup(auth, provider)
      .then(async(result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result)
        const token = credential?.accessToken || ''
        const userImpl = result.user

        const userDocRef = doc(db, "versions/1/users", userImpl.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const data = userDoc.data()
          setUser(
            new User({
              uid: userDoc.id,
              token: token,
              displayName: data.displayName,
              photoURL: userImpl.photoURL,
              type: data.type,
            })
          )
          navigate('/');
        }else{
          try {
            const data = {
              displayName: userImpl.displayName,
              photoURL: userImpl.photoURL,
              type: 'standard',
              updatedAt: serverTimestamp(),
              createdAt: serverTimestamp(),
            }
            await setDoc(userDocRef, data);

            setUser(
              new User({
                uid: userDoc.id,
                token: token,
                displayName: userImpl.displayName,
                photoURL: userImpl.photoURL,
                type: 'standard'
              })
            )
            navigate('/account');
          } catch (error) {
            console.error('Firestore への保存に失敗しました', error);
            alert('保存に失敗しました');
            navigate('/');
          }
        }
      })
      .catch(error => {})
  }

  return  (
    <Box sx={style}>
      <GoogleButton onClick={handleLogin} />
    </Box>
  );
}

export default LoginPage
