import React from 'react'
import Grid from "@mui/material/Grid2";
import useAnime from "@/hooks/useAnime";
import {Box, Container, Stack, Typography} from "@mui/material";
import StarRating from "Component/StarRating";
import HelpIcon from '@mui/icons-material/Help';
import RatingModal from "@/Component/AnimePage/AnimeSummarySection/RatingModal";
import {IRatings} from "@/models/interfaces/ratings";
import AddTitle from "@/Component/AdminPage/AddTitle";
import AnimeTitle from "@/Component/AnimePage/AnimeSummarySection/AnimeTitle";
import useUser from "@/hooks/useUser";

const ratingLabels = {
  story: 'ストーリー',
  character: 'キャラ',
  animation: '作画',
  worldview: '世界観',
  message: 'テーマ性'
}


interface AnimeSummarySectionProps {
  id: string;
}

const AnimeSummarySection: React.FC<AnimeSummarySectionProps> = props => {
  const anime = useAnime({id: props.id});
  const {user} = useUser();
  const thumbnailPrefix = 'https://storage.googleapis.com/jp-contents-matome.appspot.com/thumbnail/'
  const [openRatingModal, setOpenRatingModal] = React.useState(false);
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      <RatingModal open={openRatingModal} setOpen={setOpenRatingModal} id={props.id}/>
      <Box
        sx={{
          width: "100%",
          height: "250px",
          backgroundImage: `url(${thumbnailPrefix + props.id + '.jpg'})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter: "blur(50px)", // ぼかし効果を適用
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          // transform: "translate(-50%, -50%)",
        }}
      >
        <Box style={{height: '60px'}}/>

        <Grid container spacing={2}>
          <Grid size={0.5} />
          <Grid size={4}>
            <img src={thumbnailPrefix + props.id + '.jpg'} alt={''} style={{ width: '100%' }} />
          </Grid>
          <Grid size={7}>
            <Stack spacing={0}>
             <AnimeTitle name={anime.props.name.ja}/>

                {
                  Object.entries(ratingLabels).map(([key, value])=>(
                    <Grid container spacing={0} key={key}>
                      <Grid size={4}>
                        <Typography
                          variant="caption"
                          sx={{
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 2,
                            color: 'white',
                          }}
                        >
                          {value}
                        </Typography>
                      </Grid>

                      <Grid size={7}>
                        <Container  sx={{
                          textAlign: 'left', // 左寄せ
                          marginLeft: 0, // 親要素の左端に寄せる
                          paddingLeft: 0, // 必要ならパディングも調整
                        }}
                        onClick={()=> user.isAuthenticated() && setOpenRatingModal(true)}
                        >
                          <StarRating rating={anime.props.ratings[key as keyof IRatings]}/>
                        </Container>
                      </Grid>
                    </Grid>
                  ))
                }
            </Stack>

          </Grid>
        </Grid>

      </Box>
    </Box>

  )
}

export default AnimeSummarySection
