import React from 'react'
import { useRecoilState } from 'recoil'
import { Box, Modal } from '@mui/material'
import CircularProgress from "@mui/material/CircularProgress";
import {loadingModalState} from "@/store/loadingModalState";

const LoadingModal: React.FC = props => {
  const [open, setOpen] = useRecoilState<boolean>(loadingModalState)
  return (
    <Modal
      open={open}
      aria-labelledby="loading-modal"
      aria-describedby="loading-modal-description"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          bgcolor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <CircularProgress />
      </Box>
    </Modal>
  )
}

export default LoadingModal
