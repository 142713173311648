import {AppBar, Avatar, Box, Divider, Toolbar, Typography, useScrollTrigger} from '@mui/material'
import React, {useEffect, useState} from 'react'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useRecoilState } from 'recoil'
import { searchModalState } from '@/store/serchModalState'
import { loginModalState } from '@/store/loginModalState'
import { userStore } from '@/store/userStore'
import Stack from '@mui/material/Stack'
import useUser from '@/hooks/useUser'
import { Link } from 'react-router-dom'
import {alpha, styled} from "@mui/material/styles";
import Menu, {MenuProps} from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import {useNavigate} from "react-router-dom";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}));

const UpperAppBar: React.FC = props => {
  const [openSerchModal, setOpenSerchModal] = useRecoilState<boolean>(searchModalState)
  const [loginOpen, setLoginOpen] = useRecoilState<boolean>(loginModalState)
  const { user, setUser } = useUser()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ElevationScroll>
      <AppBar elevation={0}>
        <Toolbar>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Typography component="div">いせかいぷらす</Typography>
          </Link>
          <Box sx={{ flexGrow: 1 }}>
            <IconButton aria-label="search" onClick={() => setOpenSerchModal(true)}>
              <SearchIcon />
            </IconButton>
          </Box>

          {user.props.token !=='' && <Stack direction="row" alignItems="center">
            <Typography>{user.props.displayName}</Typography>
            <IconButton aria-label="account" onClick={!user?.isAuthenticated() ? () => navigate('/login') : handleClick}>
              {user?.isAuthenticated() ? <Avatar alt={user.props.displayName ?? ''} src={user.props.photoURL??''} sx={{ width: 28, height: 28 }} /> : <AccountCircleIcon />}
            </IconButton>
          </Stack>}
        </Toolbar>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose} disableRipple>
            <Link to={'/account'}>
              Account
            </Link>
          </MenuItem>
          <MenuItem onClick={handleClose} disableRipple>
            <Link to={'/logout'}>
              Logout
            </Link>
          </MenuItem>
          {user.isAdmin() &&
            <MenuItem onClick={handleClose} disableRipple>
              <Link to={'/admin'}>
                Admin
              </Link>
            </MenuItem>
          }
        </StyledMenu>
      </AppBar>
    </ElevationScroll>
  )
}

export default UpperAppBar

function ElevationScroll(props: any) {
  const { children } = props
  const [opacity, setOpacity] = React.useState(1)

  const handleScroll = () => {
    const maxScroll = 150;
    const scrollY = window.scrollY;

    // スクロール位置に基づいて透明度を計算
    const newOpacity = Math.max(0, Math.min(1, 1 - scrollY / maxScroll));
    setOpacity(newOpacity);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // クリーンアップ（イベントリスナーを解除）
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const interpolateColor = (start: number, end: number, factor: number) =>
    Math.round(start + (end - start) * factor);

  const textColor = `rgb(
    ${interpolateColor(25, 255, 1-opacity)},
    ${interpolateColor(50, 255, 1-opacity)},
    ${interpolateColor(56, 255, 1-opacity)}
  )`;

  return React.cloneElement(children, {
    style: {
      backgroundColor: `rgba(0, 182, 223, ${1-opacity})`,
      color: textColor,
      transition: 'opacity 0.5s ease-out',
    },
  })
}
