import { useEffect, useState } from 'react'
import { userStore } from '@/store/userStore'
import { getAuth } from 'firebase/auth'
import { useRecoilState } from 'recoil'
import {animeStore} from "@/store/animeStore";
import {doc, DocumentReference, getDoc} from "firebase/firestore";
import {db} from "@/firebase";
import User from "@/models/entities/user";
import Anime from "@/models/entities/anime";
import {ratingLabels} from "@/models/interfaces/ratings"

const useAnime = (props: {id: string}) => {
  const [anime, setAnime] = useRecoilState(animeStore);

  useEffect(() => {
    if(anime.props.id != '') return;
    const animeDocRef = doc(db, 'versions/1/animes', props.id)
    getDoc(animeDocRef).then(docSnapshot => {
      const animeData = docSnapshot.data()

      if(!animeData) return false;

      const anime = new Anime({
        id: animeData.id,
        name: animeData.name,
        tagIds: animeData.tags.map((e: any)=>e.id),
        cours: animeData.cours,
        ratings: {
          story: animeData.storyRating || 0,
          character: animeData.characterRating || 0,
          animation: animeData.animationRating || 0,
          message: animeData.messageRating || 0,
          worldview: animeData.worldviewRating || 0,
        }
        // ratings: {
        //   storyRating: animeData.storyRatings
        //     ? animeData.storyRatings.total / animeData.storyRatings.count
        //     : 0,
        //   characterRating: animeData.characterRatings
        //     ? animeData.characterRatings.total / animeData.characterRatings.count
        //     : 0,
        //   animationRating: animeData.animationRatings
        //     ? animeData.animationRatings.total / animeData.animationRatings.count
        //     : 0,
        //   messageRating: animeData.messageRatings
        //     ? animeData.messageRatings.total / animeData.messageRatings.count
        //     : 0,
        //   worldviewRating: animeData.worldviewRatings
        //     ? animeData.worldviewRatings.total / animeData.worldviewRatings.count
        //     : 0,
        // }
      })

      setAnime(anime);

    })
  }, [props.id])

  return anime
}

export default useAnime;