import useUser from '@/hooks/useUser'
import { Navigate } from 'react-router-dom'
import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const AdminGurd: React.FC<{ children: React.ReactNode }> = props => {
  const { user } = useUser()

  return user.props.type == 'admin' ? <>{props.children}</> : <Navigate to={'/'} />
}

export default AdminGurd
