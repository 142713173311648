import {useRef, useState} from 'react';
import {
  TextField,
  Button,
  Container,
  Box,
  Card,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment
} from '@mui/material';
import TagsSection from "@/Component/TagsSection";
import {collection, addDoc, doc} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { db, storage } from '@/firebase'
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import useTags from "@/hooks/useTags";

const AddTitle: React.FC = props => {
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [titleJP, setTitleJP] = useState<string>('');
  const [titleEN, setTitleEN] = useState<string>('');
  const [cours, setCours] = useState<string>('');
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [tagsState, setTagsState] = useState<string[]>([]);
  const [newTag, setNewTag] = useState<{ [key:string]: string }>({});
  const {tags, syncTags } = useTags();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    if (!titleJP || !titleEN || !thumbnail) {
      alert('すべてのフィールドを入力してください');
      return;
    }

    try {
      const docRef  = await addDoc(collection(db, 'versions/1/animes'), {
        name:{
          ja: titleJP,
          en: titleEN,
        },
        cours: cours.split(','),
        tags: tagsState.map((key)=>doc(db, key))
      });
      const storageRef = ref(storage, `thumbnail/${docRef.id}.jpg`);
      const snapshot = await uploadBytes(storageRef, thumbnail);
      alert(`アニメが追加されました:${docRef.id}`);
      navigate('/admin');
    } catch (error) {
      console.error('Firestore への保存に失敗しました', error);
      alert('保存に失敗しました');
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setThumbnail(file);
      setImageUrl(URL.createObjectURL(file));
    }
  };

  const handleAddNewTag = async (e: React.FormEvent) => {
    try {
      const docRef  = await addDoc(collection(db, 'versions/1/tags'), {
        name:newTag,
      });
      alert(`タグが追加されました:${docRef.id}`);
      syncTags();
      setNewTag({});
    } catch (error) {
      console.error('Firestore への保存に失敗しました', error);
      alert('保存に失敗しました');
    }
  }

  return (
    <Container >
      <h2>Add a New Anime Title</h2>
      <form onSubmit={handleSubmit}>
        <Button
          variant="contained"
          component="label"
          fullWidth
          sx={{ marginBottom: 2 }}
        >
          サムネイル画像を選択
          <input
            type="file"
            hidden
            onChange={handleImageChange}
            accept="image/*"
          />
        </Button>
        {imageUrl && (
          <Box sx={{ marginTop: 2 }}>
            <img src={imageUrl} alt="Thumbnail" style={{ width: '50%' }} />
          </Box>
        )}
        <TextField
          label="作品名 (JP)"
          variant="outlined"
          fullWidth
          margin="normal"
          value={titleJP}
          onChange={(e) => setTitleJP(e.target.value)}
        />
        <TextField
          label="作品名 (EN)"
          variant="outlined"
          fullWidth
          margin="normal"
          value={titleEN}
          onChange={(e) => setTitleEN(e.target.value)}
        />
        <TextField
          label="放送クール"
          variant="outlined"
          fullWidth
          margin="normal"
          value={cours}
          onChange={(e) => setCours(e.target.value)}
        />
        <TagsSection tagsState={tagsState} setTagsState={setTagsState} />
        <Card
          style={{margin: '10px', padding: '10px'}}
        >
          <TextField
            label="newTagJP"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newTag['ja']}
            onChange={(e) => setNewTag((prev) => ({...prev, 'ja':e.target.value}))}
          />
          <TextField
            label="newTagEN"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newTag['en']}
            onChange={(e) => setNewTag((prev) => ({...prev, 'en':e.target.value}))}
          />
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={handleAddNewTag}
          >
            Add New Tag
          </Button>

        </Card>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          Add Title
        </Button>
      </form>
    </Container>
  )
}

export default AddTitle
