import React from "react";
import useUser from "@/hooks/useUser";
import useTags from "@/hooks/useTags";
import {Chip, Stack, styled} from "@mui/material";
import SearchModal from "@/Component/SearchModal";
import LoginModal from "@/Component/LoginModal";
import AnimeList from "@/Component/AnimeList";
import Grid from "@mui/material/Grid2";
import Tag from "@/Component/Tag";
import { courState } from '@/store/coursState'
import { useRecoilState } from 'recoil'

const MyChip = styled(Chip)(({ theme }) => ({
  borderRadius: '10px',
  padding: '5px 8px',
  fontWeight: '600',
}))

const MyGrid = styled(Grid)(({theme})=>({
  display: "flex",
  justifyContent: "center", // 水平方向の中央揃え
  alignItems: "center",     // 垂直方向の中央揃え
  height: "100%",           // 必要に応じて高さを指定
}))

const SelectCoursSection: React.FC = props => {
  const [coursState, setCoursState] = useRecoilState<string[]>(courState)

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // 1月を1とする
  const quarters = ["冬", "春", "夏", "秋"];

  // 現在の四半期を判定
  const currentQuarterIndex = Math.floor((currentMonth-1) / 3);

  // 現在の四半期から過去3つを計算
  const buttons = Array.from({ length: 4 }, (_, i) => {
    const quarterIndex = (currentQuarterIndex - i + 4) % 4;
    const yearAdjustment = i > currentQuarterIndex ? -1 : 0; // 年の調整
    const quarterYear = currentYear + yearAdjustment;
    const label = i === 0 ? "今期" : `${quarterYear}${quarters[quarterIndex]}`;
    const key = `${quarterYear}-Q${quarterIndex+1}`
    return { label, key, year: quarterYear, quarter: quarters[quarterIndex] };
  });

  const onClick = (cours: string) => {
    setCoursState((prev) => {
      if(prev.includes(cours)) return prev.filter((item) => item !== cours);
      return [...prev, cours]
    })
  }

  return (
    <div style={{display: "flex", height: "100%", justifyContent: "center", alignItems: "center"}}>
      <Grid container spacing={0} style={{width: '400px'}} justifyContent="center" alignItems="center">
        {buttons.map((btn, index) => (
          <MyGrid size={3} key={btn.key}>
            <MyChip label={btn.label} color={coursState.includes(btn.key) ? 'primary' : 'secondary'}
                    onClick={() => onClick(btn.key)}/>
          </MyGrid>
        ))}
      </ Grid>
    </div>
  )
}

export default SelectCoursSection